import {
  ColumnDef,
  SortingState,
  createColumnHelper,
  getCoreRowModel,
  getPaginationRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table"
import { useState } from "react"

interface Props<T> {
  data?: T[]
  columns: (
    columnHelper: ReturnType<typeof createColumnHelper<T>>
  ) => ColumnDef<T, any>[]
}

const useTable = <T>({ data, columns }: Props<T>) => {
  const [globalSearch, setGlobalSearch] = useState("")
  const [sortingState, setSortingState] = useState<SortingState>([])
  const [pagination, setPagination] = useState({
    pageIndex: 0, //initial page index
    pageSize: 50, //default page size
  });

  const columnHelper = createColumnHelper<T>()

  const table = useReactTable({
    data: data ?? [],
    columns: columns(columnHelper),
    getCoreRowModel: getCoreRowModel(),
    onGlobalFilterChange: setGlobalSearch,
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    onSortingChange: setSortingState,
    getPaginationRowModel: getPaginationRowModel(),
    onPaginationChange: setPagination,
    state: {
      globalFilter: globalSearch,
      sorting: sortingState,
      pagination,
    },
  })

  const isEmpty = data?.length === 0
  const isEmptyState = !!data && isEmpty
  const isLoadedState = !!data && !isEmpty

  return {
    table,
    setGlobalSearch,
    setSortingState,
    isEmptyState,
    isLoadedState,
    sortingState,
  }
}

export default useTable
